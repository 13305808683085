import type {FC} from 'react'
import {Box} from '@mui/material/'
import WarningIcon from '@mui/icons-material/Warning'

type Props = {
    value: boolean,
}

const TrueFalseIconBasicComponent: FC<Props> = ({value}) => (
	<Box
		data-testid="true-false-icon-component"
	>
		{value === true ? <WarningIcon sx={{color: 'warning.main'}} /> : ''}
	</Box>
)

export default TrueFalseIconBasicComponent
