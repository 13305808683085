import type {FC, ReactElement} from 'react'
import {
	StrictMode, useCallback, useEffect, useState,
} from 'react'
import {
	Outlet, useLocation, useNavigate,
} from 'react-router-dom'
import {Box} from '@mui/material'
import {useDispatch} from 'react-redux'
import Intercom from '@intercom/messenger-js-sdk'
import MBAIMainSideMenu from '../../containers/Layout'
import AgreeToTerms from '../../shared/components/auth/AgreeToTerms'
import BookAMeeting from '../../shared/components/BookAMeeting'
import type {UserContextProps} from '../../containers/App/UserProvider'
import {useUserContext} from '../../containers/App/UserProvider'
import type {AuthContextProps} from '../../containers/App/AuthProvider'
import {useAuthContext} from '../../containers/App/AuthProvider'
import {useGetTenantsMutation} from '../../apis/tenants.ts'
import ErrorBoundary from '../components/ErrorBoundary'
import Appbar from '../../containers/Layout/appbar/Appbar.tsx'

const {VITE_INTERCOM_APP_ID} = import.meta.env

const DashTemplate: FC = (): ReactElement => {
	const [getTenants] = useGetTenantsMutation()
	const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
	const handleDrawerOpen = useCallback((): void => {
		setDrawerOpen((prevOpen) => !prevOpen)
	}, [])
	const {logoutUser, loginUser} = useAuthContext() as AuthContextProps
	const {currentUser, welcomeSkipped} = useUserContext() as UserContextProps

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()

	// For backwards compatibility
	// If the old auth localStorage is detected
	// Log the user out and redirect to login page
	const oldAuth = localStorage.getItem('auth')
	useEffect(() => {
		if (oldAuth) {
			const creds = JSON.parse(oldAuth)
			localStorage.removeItem('auth')
			loginUser(creds)
		}
	}, [oldAuth, dispatch, loginUser])

	useEffect(() => {
		if (currentUser && currentUser.userprofile && !currentUser.userprofile.roles) {
			logoutUser()
		}
		if (currentUser) {
			getTenants()
				.unwrap()
				.then((tenantData) => {
					if (tenantData && tenantData.length === 0) {
						navigate('/create-organization')
					}
				})
		}
	}, [currentUser, logoutUser, getTenants, navigate])

	// If the user lands on this template they are logged in
	// If so, they should never see /login, /create-account, or /reset-password
	// Redirect them to the dashboard
	useEffect(() => {
		if (location.pathname === '/login' || location.pathname === '/create-account' || location.pathname.includes('authentication')) {
			navigate('/')
		}
	}, [location.pathname, navigate])

	useEffect(() => {
		if (currentUser && currentUser.userprofile && currentUser.userprofile.onboarding_tutorial_last_step_completed === -2 && !welcomeSkipped) {
			navigate('/welcome')
		}
	}, [currentUser, welcomeSkipped, navigate])

	// Intercom initialization with currentUser data
	useEffect(() => {
		if (currentUser && currentUser.userprofile && currentUser.userprofile.intercom_hmac && VITE_INTERCOM_APP_ID) {
			Intercom({
				app_id: VITE_INTERCOM_APP_ID,
				user_id: currentUser.id.toString(),
				user_hash: currentUser.userprofile.intercom_hmac,
				name: `${currentUser.first_name} ${currentUser.last_name}`, // Use user full name
				email: currentUser.email,
				company: {
					name: currentUser.userprofile.company,
					company_id: currentUser.userprofile.object_id,
				},
			})
		}
	}, [currentUser, VITE_INTERCOM_APP_ID])

	return (
		<StrictMode>
			{
				currentUser && currentUser.userprofile && !currentUser.userprofile.has_agreed_to_terms_and_conditions ? (
					<AgreeToTerms />
				) : null
			}
			<Box
				component="main"
				data-testid="mbai-main-dash-template-component"
				sx={
					{
						flexGrow: 1,
						display: 'flex',
						minHeight: '100vh',
					}
				}
			>
				<MBAIMainSideMenu
					open={drawerOpen}
					onMenuOpen={handleDrawerOpen}
				/>
				<Box
					sx={
						{
							flexGrow: 1,
							width: 'calc(100vw - 300px)',
							overflow: 'hidden',
						}
					}
				>
					<Appbar
						open={drawerOpen}
					/>
					<ErrorBoundary>
						<Outlet />
					</ErrorBoundary>
				</Box>
			</Box>
			{
				currentUser && currentUser.userprofile && currentUser.userprofile.is_demo_user ? (
					<BookAMeeting />
				) : null
			}
		</StrictMode>
	)
}

export default DashTemplate
