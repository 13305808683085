import {
	Box,
	Typography,
	useTheme,
} from '@mui/material'
import type {GridColDef} from '@mui/x-data-grid'
import {DataGrid} from '@mui/x-data-grid'
import type {FC} from 'react'
import {useState} from 'react'
import {useGetAllCommentsQuery} from '../../../../apis/base'
import BasicCard from '../../../../shared/components/BasicCard'
import {DEFAULT_OFFSET, DEFAULT_PAGE_SIZE} from '../../../../shared/constants/preferences/pagination'
import {getFormattedDate} from '../../../../util-functions'
import {useCustomTranslation} from '../../../../shared/hooks/useCustomTranslation'
import FilterComments from './FilterComments'

const CommentsTable: FC = () => {
	const theme = useTheme()
	const [t] = useCustomTranslation('common')
	const [paginationModel, setPaginationModel] = useState({
		pageSize: DEFAULT_PAGE_SIZE,
		page: DEFAULT_OFFSET,
	})

	const [filters, setFilters] = useState({
		production_area: '',
		comment_category: '',
		machine: '',
		machine_sub_system: '',
	})

	const {data: comments, isFetching: commentsFetching} = useGetAllCommentsQuery({
		filters,
		page: paginationModel.page,
		pageSize: paginationModel.pageSize,
	})

	const uniqueProductionAreas = Array.from(
		new Map(
			comments?.results
				?.filter((comment) => comment.production_area !== null)
				.map((comment) => [comment.production_area!.id, comment.production_area!]),
		).values(),
	)

	const uniqueCategories = Array.from(
		new Map(
			comments?.results
				?.filter((comment) => comment.comment_category !== null)
				.map((comment) => [comment.comment_category!.id, comment.comment_category!]),
		).values(),
	)

	const rows = comments?.results?.map((comment) => ({
		id: comment.id,
		created_at: comment.created_at,
		production_area: comment.production_area?.name || 'N/A',
		category: comment.comment_category?.name || 'N/A',
		line: comment.machine?.name || 'N/A',
		equipment: comment.machine_sub_system?.name || 'N/A',
		text: comment.text,
	})) || []

	const columns: GridColDef[] = [
		{
			field: 'production_area',
			headerName: t('commentTable.production_area'),
			editable: false,
			flex: 1,
		},
		{
			field: 'category',
			headerName: t('commentTable.category'),
			editable: false,
			flex: 1,
		},
		{
			field: 'line',
			headerName: t('commentTable.line'),
			editable: false,
			flex: 1,
		},
		{
			field: 'equipment',
			headerName: t('commentTable.equipment'),
			editable: false,
			flex: 1,
		},
		{
			field: 'text',
			headerName: t('commentTable.text'),
			editable: false,
			flex: 2,
		},
		{
			field: 'created_at',
			headerName: t('commentTable.created_at'),
			editable: false,
			flex: 1,
			renderCell: ({value}) => (value ? getFormattedDate(value, null, {
				showDate: true,
				showYear: true,
				showTime: true,
			}) : 'N/A'),
		},
	]

	const setCurrentProductionArea = (value: string): void => setFilters((prev) => ({...prev, production_area: value}))
	const setCurrentCategory = (value: string): void => setFilters((prev) => ({...prev, comment_category: value}))
	const setCurrentLineId = (value: string): void => setFilters((prev) => ({
		...prev, machine: value, machine_sub_system: '',
	}))
	const setCurrentEquipmentId = (value: string): void => setFilters((prev) => ({...prev, machine_sub_system: value}))

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					mb: 2,
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
				data-testid="comments-table-box"
			>
				<Typography
					variant="h2"
					component="h3"
					sx={{whiteSpace: 'nowrap', alignSelf: 'center'}}
				>
					{t('comments')}
				</Typography>
				<FilterComments
					productionAreas={uniqueProductionAreas}
					categories={uniqueCategories}
					currentProductionArea={filters.production_area}
					setCurrentProductionArea={setCurrentProductionArea}
					currentCategory={filters.comment_category}
					setCurrentCategory={setCurrentCategory}
					currentLineId={filters.machine}
					setCurrentLineId={setCurrentLineId}
					currentEquipmentId={filters.machine_sub_system}
					setCurrentEquipmentId={setCurrentEquipmentId}
				/>
			</Box>
			<BasicCard>
				<DataGrid
					rows={rows}
					rowCount={comments?.count || 0}
					columns={columns}
					paginationModel={paginationModel}
					onPaginationModelChange={setPaginationModel}
					paginationMode="server"
					rowHeight={50}
					disableRowSelectionOnClick
					pageSizeOptions={[10, 50, 100]}
					loading={commentsFetching}
					sx={{
						height: 'calc(100vh - 165px)',
						border: 'none',
						'--DataGrid-containerBackground': theme.palette.background.paper,
					}}
				/>
			</BasicCard>
		</>
	)
}

export default CommentsTable
