import {
	Box, IconButton, Tooltip,
} from '@mui/material'
import type {FC, ReactElement} from 'react'
import {NavLink} from 'react-router-dom'
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone'
import {useCustomTranslation} from '../../../shared/hooks/useCustomTranslation'

const HelpItem: FC = (): ReactElement => {
	const [t] = useCustomTranslation('common')

	return (
		<Box
			id="mbai-appbar-help-item"
			data-testid="mbai-main-help-item-component"
		>
			<Tooltip
				title={t('appbar.help_item')}
				arrow
			>
				<NavLink
					to="https://support.montblanc.ai/en/"
					target="_blank"
				>
					<IconButton
						data-testid="mbai-line-management-delete-signal-rollup-component"
						data-cy="mbai-line-management-delete-signal-rollup-component"
					>
						<HelpTwoToneIcon />
					</IconButton>
				</NavLink>
			</Tooltip>
		</Box>
	)
}

export default HelpItem
