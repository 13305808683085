import {useTheme} from '@mui/material/styles'

export const useAnomalyStatusColor = (): {
  getAnomalyStatusColor: (color: string) => string,
} => {
	const theme = useTheme()

	const getAnomalyStatusColor = (color: string): string => {
		switch (color) {
			case 'red':
				return theme.palette.error.main
			case 'green':
				return theme.palette.success.main
			case 'orange':
				return theme.palette.primary.main
			default:
				return theme.palette.error.main
		}
	}

	return {getAnomalyStatusColor}
}

export default useAnomalyStatusColor
